import Storage from "./Storage";
import SwiftBar from "./SwiftBar";
import CachePage from "./CachePage";
import { styled } from "@mui/system";
import AnalyticsPage from "./AnalyticsPage";
import { useAppState } from "../AppContext";
import SnackBar from "./SnackBar";
import UploadProgress from "./UploadProgress";

const MainContent = styled("main")(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(0),
  marginLeft: 240,
  overflow: "auto",
}));

export default function Main() {
  // Get the shared state using the useAppState hook
  const { state } = useAppState();

  return (
    <div>
      {state.uploadProgress > 0 && <UploadProgress />}
      <SwiftBar />
      <MainContent>
        <SnackBar />
        {state.showCachePage && <CachePage />}
        {state.showAnalyticsPage && <AnalyticsPage />}
        {state.showDefaultPage && <Storage />}
      </MainContent>
    </div>
  );
}
