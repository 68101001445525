import * as React from "react";
import { useAppState } from "../AppContext";
import HomeIcon from "@mui/icons-material/Home";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";

export default function Breadcrumb() {
  // Get the shared state and dispatch function using the useAppState hook
  const { state, dispatch } = useAppState();

  // Don't render breadcrumb when at root level
  if (state.currentPath === "") {
    return null;
  }

  // Split the path into components
  const pathComponents = state.currentPath.split("/");

  // Handler function for clicking on a breadcrumb
  const handleClick = (index) => {
    // Update the current path in the state by joining the path components up to
    // the clicked component with a "/" separator
    dispatch({
      type: "SET_CURRENT_PATH",
      payload: pathComponents.slice(0, index + 1).join("/"),
    });
  };

  // Render the breadcrumb trail with clickable components for each path component
  return (
    <div role="presentation" style={{ paddingLeft: "16px", paddingTop: "8px" }}>
      <Breadcrumbs maxItems={2} aria-label="breadcrumb">
        {/* Render a home icon as the first breadcrumb */}
        <span onClick={() => handleClick(-1)} style={{ cursor: "pointer" }}>
          <HomeIcon style={{ marginRight: "4px" }} />
        </span>
        {/* Render a clickable component for each path component */}
        {pathComponents.map((component, index) => {
          if (component) {
            // Capitalize the first letter of the path component and render it as a clickable span
            return (
              <span
                key={index}
                onClick={() => handleClick(index)}
                style={{ cursor: "pointer" }}
              >
                {component.charAt(0).toUpperCase() + component.slice(1)}
              </span>
            );
          }
          return null;
        })}
        {/* Render the last path component as plain text */}
        <Typography color="text.primary">
          {pathComponents[pathComponents.length - 1]}
        </Typography>
      </Breadcrumbs>
    </div>
  );
}
