import { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Paper,
} from "@mui/material";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { styled } from "@mui/system";
import { useAppState } from "../AppContext";
import { red, grey, blue } from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/Close";

// List of text file extensions
const textFileExtensions = [
  "js", // JavaScript
  "java", // Java
  "py", // Python
  "html", // HTML
  "css", // CSS
  "xml", // XML
  "json", // JSON
  "md", // Markdown
  "yaml", // YAML
  "sql", // SQL
  "txt", // Text
  "csv", // CSV
  "php", // PHP
  "rb", // Ruby
  "c", // C
  "cpp", // C++
  "swift", // Swift
  "go", // Go
  "ts", // TypeScript
];

// Function to get the file extension from the filename
const getFileExtension = (filename) => {
  const lastDotIndex = filename.lastIndexOf(".");
  if (
    lastDotIndex === -1 ||
    lastDotIndex === 0 ||
    lastDotIndex === filename.length - 1
  ) {
    return null; // Return null if no extension or dot is at the beginning/end
  }
  return filename.slice(lastDotIndex + 1).toLowerCase();
};

// Styling for the dialog
const StyledDialog = styled(Dialog)(({ theme }) => ({
  // Styles for the dialog title
  "& .MuiDialogTitle-root": {
    background: blue[700],
    padding: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: grey[50],
  },

  "& .MuiDialogTitle-root .MuiTypography-root": {
    margin: 0,
    fontSize: "16px",
    fontWeight: "bold",
  },

  // Styles for the dialog content
  "& .MuiDialogContent-root": {
    padding: 0,
    height: "100%",
  },

  // Styles for the file preview container
  "& .file-preview": {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    objectFit: "contain",
  },

  // Styles for the close button
  "& .close-button": {
    color: "#FFF",
    marginRight: theme.spacing(0),
    "&:hover": {
      color: red[500],
    },
  },

  "& .MuiIconButton-root:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
  },

  "& .MuiPaper-root": {
    margin: 0,
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
}));

export default function FilePreview() {
  const { state, dispatch } = useAppState();
  const [textFileContent, setTextFileContent] = useState("");
  const fileExtension = getFileExtension(state.filePreview.name);

  // Function to handle the close button click
  const handleClose = () => {
    dispatch({ type: "SET_FILE_PREVIEW", payload: null });
  };

  // Function to check if a file extension is a text file
  const isTextFile = (extension) => {
    return textFileExtensions.includes(extension);
  };

  // Function to render the text content using syntax highlighting
  const renderTextContent = (content) => {
    return (
      <SyntaxHighlighter
        language={fileExtension}
        style={materialDark}
        wrapLines={true}
        showLineNumbers={true}
        customStyle={{ width: "100%", height: "100%", margin: 0, padding: 0 }}
      >
        {content}
      </SyntaxHighlighter>
    );
  };

  // Function to render the appropriate file preview based on the file extension
  const renderFilePreview = () => {
    if (isTextFile(fileExtension)) {
      // Render text content with syntax highlighting
      return (
        <div className="file-preview">{renderTextContent(textFileContent)}</div>
      );
    } else if (["png", "jpg", "jpeg", "gif", "webp"].includes(fileExtension)) {
      // Render image file
      return (
        <img
          src={state.filePreview.signedLink}
          alt={state.filePreview.name}
          className="file-preview"
        />
      );
    } else if (["mp3", "wav", "ogg", "m4a", "weba"].includes(fileExtension)) {
      // Render audio file
      return (
        <audio
          controls
          autoPlay
          loop
          src={state.filePreview.signedLink}
          className="file-preview"
        />
      );
    } else if (["mp4", "webm", "ogg"].includes(fileExtension)) {
      // Render video file
      return (
        <video
          controls
          autoPlay
          loop
          src={state.filePreview.signedLink}
          className="file-preview"
        />
      );
    } else {
      // Handle unsupported file types
      handleClose();
      dispatch({
        type: "SET_ERROR",
        payload: "Cannot preview this file",
      });
      return null;
    }
  };

  useEffect(() => {
    // Fetch the file content
    const fetchContent = async () => {
      try {
        const fetchFileContent = async (url) => {
          try {
            const response = await fetch(url);
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            const content = await response.text();
            return content;
          } catch (error) {
            throw new Error("Failed to fetch file content");
          }
        };

        if (isTextFile(fileExtension)) {
          const response = await fetchFileContent(state.filePreview.signedLink);
          setTextFileContent(response);
        }
      } catch (error) {
        dispatch({
          type: "SET_ERROR",
          payload: "Failed to fetch file content",
        });
      }
    };

    fetchContent().catch(() => {
      dispatch({
        type: "SET_ERROR",
        payload: "Failed to fetch file content",
      });
    });
  }, [fileExtension, state.filePreview.signedLink, dispatch]);

  return (
    <div>
      <StyledDialog
        open={true}
        onClose={handleClose}
        fullScreen={true}
        PaperComponent={Paper}
      >
        <DialogTitle>
          {`Preview of ${state.filePreview.name}`}
          <IconButton
            className="close-button"
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>{renderFilePreview()}</DialogContent>
      </StyledDialog>
    </div>
  );
}
