import { useAppState } from "../AppContext";
import { LinearProgress } from "@mui/material";
import { keyframes } from "@emotion/react";

export default function UploadProgress() {
  const { state } = useAppState();

  const waveAnimation = keyframes`
    0% {
      background-position: -100% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: -100% 50%;
    }
  `;

  return (
    <LinearProgress
      variant="determinate"
      value={state.uploadProgress}
      sx={{
        "& .MuiLinearProgress-bar": {
          backgroundImage: `linear-gradient(to right, #B32CE2, #6C2CE2, #009BDE)`,
          backgroundSize: "300% 100%",
          animation: `${waveAnimation} 5s linear infinite`,
        },
      }}
    />
  );
}
