// Import necessary libraries
import { Storage } from "aws-amplify";

// Define utility functions for S3 file system operations
function S3FsOps(state, dispatch) {
  // Set the default S3 bucket level to be used
  const s3Level = "public";

  // Set the default S3 page size to be used
  const s3PageSize = 1000;

  /**
   * Function to traverse the S3 tree structure
   * and return all files and objects in a specific directory.
   *
   * @param {String} path - Directory path to be traversed.
   * @returns {Array} - Array of objects for each file or directory found in
   * the given path.
   */
  const s3FsTraverse = async () => {
    let path = state.currentPath;
    const s3Tree = state.s3FileSystem;

    // Initialize currentNode to the root of the s3Tree
    let currentNode = s3Tree;

    // If the path is not empty, traverse the s3Tree
    if (path !== "") {
      // Remove the leading "/" from the path if present
      path = path.startsWith("/") ? path.substring(1) : path;

      // Split the path into components
      const pathComponents = path.split("/");

      // Iterate through each component (directory or file name) in the pathComponents array
      for (const component of pathComponents) {
        if (component && currentNode[component]) {
          currentNode = currentNode[component];
        } else {
          // If the path is not found in the tree, return an empty array
          return [];
        }
      }

      // If the currentNode is not a directory, return an empty array
      if (currentNode.type !== "directory") {
        return [];
      }
    }

    // Initialize an empty array called 'entries' to store the contents of the target directory
    const entries = [];
    // Iterate through all keys (file or directory names) in the currentNode object
    for (const key in currentNode) {
      // Check if the current key is an own property of the currentNode object and is not the reserved "type" key
      if (currentNode.hasOwnProperty(key) && key !== "type") {
        // Add the current key and its corresponding object from the currentNode to the 'entries' array
        // 'name' is set to the current key, and the rest of the properties are spread using the ... operator
        entries.push({ name: key, ...currentNode[key] });
      }
    }

    // Return the 'entries' array containing the contents of the target directory
    dispatch({ type: "SET_DIR_CONTENT", payload: entries });
  };

  /**
   * Helper function to build the S3 tree structure from an object list.
   *
   * @param {Array} objList - List of objects fetched from the S3 storage.
   * @returns {Object} - Tree structure representing the hierarchical
   * relationships of the objects.
   */
  const buildS3Tree = async (objList) => {
    const s3Tree = {};

    const fetchSignedLinks = async () => {
      const signedLinks = new Map();

      const fetchPromises = objList.map(async (obj) => {
        const signedLink = Storage.get(obj.key, {
          level: s3Level,
          expires: 604800, // 7 days
        });
        signedLinks.set(obj.key, signedLink);
      });

      await Promise.all(fetchPromises);
      return signedLinks;
    };

    const signedLinks = await fetchSignedLinks();

    for (const obj of objList) {
      const pathComponents = obj.key.split("/");
      let currentNode = s3Tree;

      for (const [index, part] of pathComponents.entries()) {
        if (!part || part === ":") {
          continue;
        }

        if (!currentNode[part]) {
          if (index === pathComponents.length - 1) {
            // It's a file.
            const size = obj.size;
            const signedLink = await signedLinks.get(obj.key);
            const cdnLink = `https://sonic.swiftrise.wmie.it/${obj.key}`;

            currentNode[part] = {
              type: "file",
              size: size,
              signedLink: signedLink,
              cdnLink: cdnLink,
            };
          } else {
            // It's a directory.
            currentNode[part] = {
              type: "directory",
            };
          }
        }
        currentNode = currentNode[part];
      }
    }

    return s3Tree;
  };

  /**
   * Fetches a list of files and directories from an S3 storage and constructs a
   * tree data structure representing their hierarchical relationships. The
   * tree is then utilized to update the state.
   *
   * @param {string} [path=""] - Optional starting directory path in the S3
   * storage. Default is an empty string, which corresponds to the root
   * directory.
   * @returns {void} - No return value. The function updates the state with the
   * constructed S3 tree.
   */
  const fetchFiles = async (path = "") => {
    try {
      const objList = await Storage.list(path, {
        level: s3Level,
        pageSize: s3PageSize,
      });

      const s3Tree = await buildS3Tree(objList.results);

      dispatch({ type: "SET_S3_FILE_SYSTEM", payload: s3Tree });
    } catch (error) {
      dispatch({
        type: "SET_ERROR",
        payload: "Failed to fetch files from S3 storage.",
      });
    }
  };

  // Downloads a file from S3 bucket
  const downloadFile = async (fileKey) => {
    try {
      const signedURL = await Storage.get(fileKey, { expires: 3600 });
      const response = await fetch(signedURL);
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileKey.split("/").pop(); // set the downloaded file's name
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      dispatch({
        type: "SET_ERROR",
        payload: "Error downloading file",
      });
    }
  };

  // Deletes a file or folder from S3 bucket
  const deleteFile = async (fileKey, fileType) => {
    try {
      if (fileType === "directory") {
        const files = await Storage.list(fileKey, {
          level: s3Level,
          pageSize: s3PageSize,
        });
        await Promise.all(
          files.results.map(({ key }) => deleteFile(key, null))
        );

        // Remove the folder itself after deleting its contents
        await Storage.remove(fileKey, { level: s3Level });
      } else {
        await Storage.remove(fileKey, { level: s3Level });
      }

      fetchFiles(); // update file list after deleting a file or directory
    } catch (error) {
      dispatch({
        type: "SET_ERROR",
        payload: `Error deleting ${fileType}`,
      });
    }
  };

  // Renames a file or folder in S3 bucket
  const renameFile = async (fileKey, newFileName, fileType) => {
    try {
      if (fileType === "directory") {
        const files = await Storage.list(fileKey, {
          level: s3Level,
          pageSize: s3PageSize,
        });

        await Promise.all(
          files.results.map(({ key, type }) =>
            renameFile(key, `${newFileName}/${key.split("/").pop()}`, type)
          )
        );
      }

      // Check that the source key exists
      const key = await Storage.get(fileKey);

      if (key) {
        // Rename the target file or folder
        await Storage.copy({ key: fileKey }, { key: newFileName });
        await Storage.remove(fileKey);
      }

      fetchFiles(); // update file list after renaming a file or folder
    } catch (error) {
      dispatch({
        type: "SET_ERROR",
        payload: `Error renaming ${fileType}`,
      });
    }
  };

  // Creates a new folder in S3 bucket
  const createFolder = async (folderName) => {
    try {
      let folderKey = `${folderName}/`;

      // Remove leading slash if currentPath is root
      folderKey = state.currentPath === "" ? folderKey.substring(1) : folderKey;
      await Storage.put(folderKey, "", {
        contentType: "application/x-directory",
      });
      fetchFiles(); // update file list after creating a new folder
    } catch (error) {
      dispatch({
        type: "SET_ERROR",
        payload: "Error creating folder",
      });
    }
  };

  // Uploads a file to S3 bucket
  const uploadFile = async (file) => {
    try {
      let filename = `${state.currentPath}/${file.name}`;
      // Remove leading slash if currentPath is root
      filename = state.currentPath === "" ? filename.substring(1) : filename;

      const progressCallback = (progress) => {
        dispatch({
          type: "SET_UPLOAD_PROGRESS",
          payload: (progress.loaded / progress.total) * 100,
        });
      };
      await Storage.put(filename, file, { progressCallback });
      fetchFiles(); // update file list after uploading a new file
      dispatch({ type: "SET_UPLOAD_PROGRESS", payload: 0 }); // reset upload progress
      dispatch({
        type: "SET_MESSAGE",
        payload: `${file.name} uploaded successfully`,
      }); // notify user of successful upload
    } catch (error) {
      dispatch({
        type: "SET_ERROR",
        payload: `Error uploading ${file.name}`,
      });
    }
  };

  // Return object with all utility functions as properties
  return {
    s3FsTraverse,
    fetchFiles,
    renameFile,
    downloadFile,
    deleteFile,
    createFolder,
    uploadFile,
  };
}
// Export the file operations utility functions
export default S3FsOps;
