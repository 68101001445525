import React from "react";
import { Auth } from "aws-amplify";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import S3FsOps from "../utils/s3FsOps";
import AppBar from "@mui/material/AppBar";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Logo from "../assets/wmie-logo.svg";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import { useAppState } from "../AppContext";
import Tooltip from "@mui/material/Tooltip";
import ListItem from "@mui/material/ListItem";
import AddIcon from "@mui/icons-material/Add";
import { ListItemButton } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import Typography from "@mui/material/Typography";
import CloudIcon from "@mui/icons-material/Cloud";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import StorageIcon from "@mui/icons-material/Storage";
import LogoutIcon from "@mui/icons-material/ExitToApp";
import BarChartIcon from "@mui/icons-material/BarChart";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius * 2,
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    border: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
  },
  marginLeft: 0,
  marginRight: theme.spacing(1),
  width: "100%",
  padding: theme.spacing(0, 1.5),
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
  display: "flex",
  alignItems: "center",
  border: `1px solid ${alpha(theme.palette.primary.main, 0.3)}`,
  transition: "background-color 0.3s, border-color 0.3s",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "20ch",
      "&:focus": {
        width: "30ch",
      },
    },
  },
}));

export default function SwiftBar() {
  // Get the shared state and dispatch function using the useAppState hook
  const { state, dispatch } = useAppState();

  // Create an instance of the S3FsOps module, which provides a higher-level
  // interface for working with AWS S3 buckets
  const s3FsOps = S3FsOps(state, dispatch);

  // Handler function for the logout button
  const handleLogout = async () => {
    try {
      await Auth.signOut();
      // Perform any additional actions after successful logout, e.g., updating
      // the UI or redirecting the user
    } catch (error) {
      // Dispatch an error action to update the state with the error message
      dispatch({ type: "SET_ERROR", payload: "Error signing out" });
    }
  };

  // Handler function for the search input field
  const handleSearchInputChange = (event) => {
    // Dispatch a search input action to update the state with the new value
    dispatch({ type: "SET_SEARCH_INPUT", payload: event.target.value });
  };

  // Handler function for the new folder button
  const handleNewFolderClick = () => {
    const folderName = prompt("Enter new folder name");
    if (folderName) {
      // Call the createFolder method of the S3FsOps instance to
      // create a new folder
      s3FsOps.createFolder(`${state.currentPath}/${folderName}`);
    }
  };

  // Handler function for the upload button
  const handleFileSelection = (event) => {
    const file = event.target.files[0];
    if (file) {
      dispatch({ type: "SET_INFO", payload: `Uploading ${file.name}...` });
      // Call the uploadFile method of the S3FsOps instance to upload the file
      s3FsOps.uploadFile(file);
    }
  };

  const handleStorageClick = () => {
    dispatch({ type: "SET_SHOW_ANALYTICS", payload: false });
    dispatch({ type: "SET_SHOW_CACHE", payload: false });
    dispatch({ type: "SET_SHOW_DEFAULT", payload: true });
    dispatch({ type: "SET_SHOW_APPBAR", payload: true });
  };

  const handleCacheClick = () => {
    dispatch({ type: "SET_SHOW_APPBAR", payload: false });
    dispatch({ type: "SET_SHOW_DEFAULT", payload: false });
    dispatch({ type: "SET_SHOW_ANALYTICS", payload: false });
    dispatch({ type: "SET_SHOW_CACHE", payload: true });
  };

  const handleAnalyticsClick = () => {
    dispatch({ type: "SET_SHOW_APPBAR", payload: false });
    dispatch({ type: "SET_SHOW_DEFAULT", payload: false });
    dispatch({ type: "SET_SHOW_CACHE", payload: false });
    dispatch({ type: "SET_SHOW_ANALYTICS", payload: true });
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Drawer
        sx={{
          width: 240,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 240,
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            flexDirection: "row",
            paddingLeft: 2,
          }}
        >
          <img
            src={Logo}
            alt="logo"
            style={{ maxHeight: "30px", marginRight: "8px" }}
          />
          <Typography variant="h6" noWrap>
            Swiftrise
          </Typography>
        </Toolbar>
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={handleLogout}>
              <ListItemIcon>
                <LogoutIcon color="warning" />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={handleStorageClick}>
              <ListItemIcon>
                <CloudIcon color="Primary" />
              </ListItemIcon>
              <ListItemText primary="Storage" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={handleCacheClick}>
              <ListItemIcon>
                <StorageIcon />
              </ListItemIcon>
              <ListItemText primary="Cache" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={handleAnalyticsClick}>
              <ListItemIcon>
                <BarChartIcon />
              </ListItemIcon>
              <ListItemText primary="Analytics" />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>

      {state.showAppBar && (
        <AppBar position="static">
          <Toolbar>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ display: { xs: "none", sm: "block" } }}
            >
              Swiftrise
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", sm: "flex" },
                ml: { sm: "120px" },
              }}
            >
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search files or folders"
                  inputProps={{ "aria-label": "search" }}
                  onChange={handleSearchInputChange}
                />
              </Search>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
              <Tooltip title="New Folder" arrow>
                <IconButton color="inherit" onClick={handleNewFolderClick}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
              <Box sx={{ ml: 1 }}>
                <input
                  accept="*"
                  id="upload-file"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleFileSelection}
                />
                <label htmlFor="upload-file">
                  <Tooltip title="Upload" arrow>
                    <Button
                      component="span"
                      color="inherit"
                      startIcon={<CloudUploadIcon />}
                    >
                      Upload
                    </Button>
                  </Tooltip>
                </label>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
      )}
    </Box>
  );
}
