// import { useAppState } from "../AppContext";

export default function AnalyticsPage() {
  // Get the shared state and dispatch function using the useAppState hook
  // const { state, dispatch } = useAppState();

  return (
    <div>
      <center>
        <h1>Analytics Page: Coming soon</h1>
      </center>
    </div>
  );
}
