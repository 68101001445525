import React, { createContext, useContext, useReducer } from "react";

// Define the initial state of the application
const initialState = {
  info: null,
  error: null,
  message: null,
  dirContent: [],
  currentPath: "",
  searchInput: "",
  showAppBar: true,
  searchResults: [],
  uploadProgress: 0,
  filePreview: null,
  s3FileSystem: null,
  showCachePage: false,
  showDefaultPage: true,
  showAnalyticsPage: false,
};

// Define the reducer function to update the state
const appReducer = (state, action) => {
  switch (action.type) {
    case "SET_S3_FILE_SYSTEM":
      return { ...state, s3FileSystem: action.payload };
    case "SET_CURRENT_PATH":
      return { ...state, currentPath: action.payload };
    case "SET_DIR_CONTENT":
      return { ...state, dirContent: action.payload };
    case "SET_SEARCH_INPUT":
      return { ...state, searchInput: action.payload };
    case "SET_SEARCH_RESULTS":
      return { ...state, searchResults: action.payload };
    case "SET_UPLOAD_PROGRESS":
      return { ...state, uploadProgress: action.payload };
    case "SET_SHOW_CACHE":
      return { ...state, showCachePage: action.payload };
    case "SET_SHOW_ANALYTICS":
      return { ...state, showAnalyticsPage: action.payload };
    case "SET_SHOW_DEFAULT":
      return { ...state, showDefaultPage: action.payload };
    case "SET_SHOW_APPBAR":
      return { ...state, showAppBar: action.payload };
    case "SET_FILE_PREVIEW":
      return { ...state, filePreview: action.payload };
    case "SET_ERROR":
      return { ...state, error: action.payload };
    case "SET_MESSAGE":
      return { ...state, message: action.payload };
    case "SET_INFO":
      return { ...state, info: action.payload };
    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
};

// Create a new context object
const AppContext = createContext();

// Create an AppProvider component to wrap the entire application
export const AppProvider = ({ children }) => {
  // Use the useReducer hook to manage state with the appReducer function and initial state
  const [state, dispatch] = useReducer(appReducer, initialState);

  // Memoize the value object to avoid unnecessary re-renders
  const value = React.useMemo(() => ({ state, dispatch }), [state, dispatch]);

  // Render the AppContext Provider component, providing the state and dispatch properties to all children components
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

// Create a custom hook, useAppState, to access the state and dispatch properties from within any component that is wrapped by the AppProvider
export const useAppState = () => {
  // Use the useContext hook to access the state and dispatch properties from the AppContext Provider
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppState must be used within an AppProvider");
  }
  return context;
};
