import List from "@mui/material/List";
import Menu from "@mui/material/Menu";
import Breadcrumb from "./Breadcrumb";
import S3FsOps from "../utils/s3FsOps";
import { useState, useEffect } from "react";
import { useAppState } from "../AppContext";
import ListItem from "@mui/material/ListItem";
import MenuItem from "@mui/material/MenuItem";
import { ListItemButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";
import GetAppIcon from "@mui/icons-material/GetApp";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import FilePreview from "./FilePreview";

export default function Storage() {
  // Get the shared state and dispatch function using the useAppState hook
  const { state, dispatch } = useAppState();

  // Create an instance of the S3FsOps module, which provides a higher-level
  // interface for working with AWS S3 buckets
  const s3FsOps = S3FsOps(state, dispatch);

  // Define a variable to hold the files to be displayed in the FileList
  // component and Initialize it to the dirContent state
  const files =
    state.searchInput !== "" ? state.searchResults : state.dirContent;

  // Fetch files on mount and when the current path or the s3FileSystem changes
  useEffect(() => {
    // Execute the appropriate function based on the dependencies
    if (state.currentPath === "" && state.s3FileSystem === null) {
      // Fetch files
      s3FsOps.fetchFiles();
    } else {
      // Traverse the S3 file system
      s3FsOps.s3FsTraverse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currentPath, state.s3FileSystem]);

  // Search for files whenever the search input changes
  useEffect(() => {
    // Only perform the search if there is a search input
    if (state.searchInput !== "") {
      // Search logic here
      const filteredFiles = state.dirContent.filter((item) => {
        // Check if the name property of the item includes the search input
        const itemName = item.hasOwnProperty("name")
          ? item.name.toLowerCase()
          : "";
        return itemName.includes(state.searchInput.toLowerCase());
      });

      // Dispatch the action to update the searchResults state
      dispatch({ type: "SET_SEARCH_RESULTS", payload: filteredFiles });
    } else {
      // If there is no search input, reset the searchResults state to the original content
      dispatch({ type: "SET_SEARCH_RESULTS", payload: [] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.searchInput, state.dirContent]);

  const [contextMenuProps, setContextMenuProps] = useState(null);

  const handleContextMenuClick = (event, action, item) => {
    let target = "";
    event.preventDefault();
    setContextMenuProps(null);

    switch (action) {
      case "rename":
        if (item.name === "CodeBento") {
          // Protecting the CodeBento folder from renaming
          dispatch({
            type: "SET_ERROR",
            payload: `${item.name} is a protected folder`,
          });

          break;
        }
        target =
          state.currentPath === ""
            ? item.name
            : `${state.currentPath}/${item.name}`;
        // handle renaming logic
        const newName = prompt("Enter new name", item.name);
        if (newName && newName !== item.name) {
          const newPath =
            state.currentPath === ""
              ? newName
              : `${state.currentPath}/${newName}`;
          s3FsOps.renameFile(target, newPath, item.type);
        }
        dispatch({
          type: "SET_MESSAGE",
          payload: `Renamed ${item.name} in ${newName}`,
        });
        break;
      case "delete":
        if (item.name === "CodeBento") {
          // Protecting the CodeBento folder from deletion
          dispatch({
            type: "SET_ERROR",
            payload: `${item.name} is a protected folder`,
          });

          break;
        }
        target =
          state.currentPath === ""
            ? item.name
            : `${state.currentPath}/${item.name}`;
        dispatch({ type: "SET_MESSAGE", payload: `Deleted ${item.name}` });
        // handle deleting logic
        s3FsOps.deleteFile(target, item.type);
        break;
      case "copy_no_cache_link":
        dispatch({
          type: "SET_MESSAGE",
          payload: `Copied no-cache link ${item.name} `,
        });
        // handle copy direct link logic
        navigator.clipboard.writeText(item.signedLink);
        break;
      case "copy_sonic_link":
        dispatch({
          type: "SET_MESSAGE",
          payload: `Copied sonic link ${item.name}`,
        });
        // handle copy sonic link logic
        navigator.clipboard.writeText(item.cdnLink);
        break;
      case "download":
        target =
          state.currentPath === ""
            ? item.name
            : `${state.currentPath}/${item.name}`;
        dispatch({ type: "SET_INFO", payload: `Downloading ${item.name}` });
        // handle downloading logic
        s3FsOps.downloadFile(target);
        break;
      default:
        dispatch({ type: "SET_ERROR", payload: `Unknown action: ${action}` });
    }
  };

  return (
    <div onContextMenu={(e) => e.preventDefault()}>
      {/* Breadcrumb that shows the current path */}
      <Breadcrumb />
      {/* File list is rendered here */}
      <List>
        {files.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              onContextMenu={(event) =>
                setContextMenuProps({
                  item,
                  mouseX: event.clientX,
                  mouseY: event.clientY,
                })
              }
              onClick={() => {
                if (item.type === "directory") {
                  const newPath =
                    state.currentPath === ""
                      ? item.name
                      : `${state.currentPath}/${item.name}`;
                  dispatch({ type: "SET_CURRENT_PATH", payload: newPath });
                } else if (item.type === "file") {
                  dispatch({ type: "SET_FILE_PREVIEW", payload: item });
                }
              }}
            >
              <ListItemIcon>
                {item.type === "file" ? (
                  <InsertDriveFileIcon />
                ) : (
                  <FolderIcon />
                )}
              </ListItemIcon>
              <ListItemText
                primary={item.name}
                secondary={
                  item.type === "file"
                    ? `${(item.size / 1024).toFixed(2)} KB`
                    : ""
                }
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      {/* File preview is rendered here */}
      {state.filePreview !== null && <FilePreview />}
      {/* Context menu is rendered here */}
      <Menu
        open={Boolean(contextMenuProps)}
        onClose={() => setContextMenuProps(null)}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenuProps !== null &&
          contextMenuProps.mouseY !== null &&
          contextMenuProps.mouseX !== null
            ? { top: contextMenuProps.mouseY, left: contextMenuProps.mouseX }
            : undefined
        }
      >
        <MenuItem
          onClick={(event) =>
            handleContextMenuClick(event, "rename", contextMenuProps.item)
          }
        >
          <EditIcon color="warning" sx={{ marginRight: "8px" }} />
          Rename
        </MenuItem>
        <MenuItem
          onClick={(event) =>
            handleContextMenuClick(event, "delete", contextMenuProps.item)
          }
        >
          <DeleteIcon color="error" sx={{ marginRight: "8px" }} />
          Delete
        </MenuItem>
        <MenuItem
          onClick={(event) =>
            handleContextMenuClick(
              event,
              "copy_sonic_link",
              contextMenuProps.item
            )
          }
        >
          <FlashOnIcon sx={{ marginRight: "8px", color: "#FFC400" }} />
          Copy sonic link
        </MenuItem>
        <MenuItem
          onClick={(event) =>
            handleContextMenuClick(
              event,
              "copy_no_cache_link",
              contextMenuProps.item
            )
          }
        >
          <FileCopyIcon color="primary" sx={{ marginRight: "8px" }} />
          Copy no-cache link
        </MenuItem>
        <MenuItem
          onClick={(event) =>
            handleContextMenuClick(event, "download", contextMenuProps.item)
          }
        >
          <GetAppIcon color="success" sx={{ marginRight: "8px" }} />
          Download
        </MenuItem>
      </Menu>
    </div>
  );
}
