import { useState, useEffect } from "react";
import { useAppState } from "../AppContext";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";

export default function SnackBar() {
  // Get the shared state and dispatch function using the useAppState hook
  const { state, dispatch } = useAppState();

  const [open, setOpen] = useState(false);

  // Use useEffect to listen for changes in state.message and state.error
  useEffect(() => {
    if (state.message || state.error || state.info) {
      setOpen(true);
    }
  }, [state.message, state.error, state.info]);

  const handleCloseSnackbar = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);

    // Reset message and error states after a delay
    setTimeout(() => {
      dispatch({ type: "SET_ERROR", payload: null });
      dispatch({ type: "SET_MESSAGE", payload: null });
      dispatch({ type: "SET_INFO", payload: null });
    }, 500);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={handleCloseSnackbar}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert
        onClose={handleCloseSnackbar}
        severity={state.error ? "error" : state.info ? "info" : "success"}
        elevation={6}
        variant="filled"
      >
        {state.error || state.info || state.message}
      </Alert>
    </Snackbar>
  );
}
