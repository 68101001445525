import { Auth } from "aws-amplify";
import { CloudFront } from "aws-sdk";
import { useAppState } from "../AppContext";
import {
  Button,
  Typography,
  Container,
  Box,
  Card,
  CardContent,
  Grid,
} from "@mui/material";

export default function CachePage() {
  // Get the shared state and dispatch function using the useAppState hook
  const { dispatch } = useAppState();

  const handleInvalidateCache = async () => {
    const cloudfront = new CloudFront();
    // Swiftrise CloudFront distribution ID
    const distributionId = "E3PN01REPMTIKI";
    const params = {
      DistributionId: distributionId,
      InvalidationBatch: {
        CallerReference: Date.now().toString(),
        Paths: {
          Quantity: 1,
          Items: ["/*"],
        },
      },
    };
    try {
      const credentials = await Auth.currentCredentials();
      const accessKeyId = credentials.accessKeyId;
      const secretAccessKey = credentials.secretAccessKey;
      const sessionToken = credentials.sessionToken;
      cloudfront.config.credentials = {
        accessKeyId,
        secretAccessKey,
        sessionToken,
      };
      await cloudfront.createInvalidation(params).promise();
      dispatch({
        type: "SET_MESSAGE",
        payload: "Cache invalidation in progress it could take a few minutes",
      });
    } catch (error) {
      dispatch({
        type: "SET_ERROR",
        payload: "Error invalidating Sonic cache",
      });
    }
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Manage Sonic Cache
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container>
                  <Grid item xs={12} md={8}>
                    <Typography variant="h6" component="h2">
                      Invalidate Cache
                    </Typography>
                    <Typography color="text.secondary" sx={{ my: 2 }}>
                      Invalidation purges cached content from Swiftrise edge
                      locations. When a file is invalidated, it is immediately
                      removed from the cache, ensuring that the next request for
                      the file is fetched from the origin server. Use this
                      option to remove outdated or incorrect content from the
                      cache.
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleInvalidateCache}
                      size="large"
                    >
                      Purge Cache
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
