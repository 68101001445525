// Import necessary libraries and components
import React from "react";
import "@fontsource/roboto/300.css"; // Import Roboto font weights
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import Main from "./components/Main"; // Import the Main component
import "@aws-amplify/ui-react/styles.css"; // Import Amplify UI React styles
import { AppProvider } from "./AppContext"; // Import the App context provider
// Import the withAuthenticator HOC
import { withAuthenticator } from "@aws-amplify/ui-react";

// App component
function App() {
  // Render the App component wrapped with AppProvider and withAuthenticator
  return (
    <AppProvider>
      <Main />
    </AppProvider>
  );
}

// Export the App component, wrapping it with the withAuthenticator HOC
// Configure it to use email-based authentication and hide the sign-up 
// functionality
export default withAuthenticator(App, {
  loginMechanisms: ["email"],
  hideSignUp: true,
});
